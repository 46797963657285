<template>
  <div class="countdown-page">
    <h1>Das Quiz beginnt in...</h1>

    <!-- Countdown-Zähler -->
    <p class="circle" :class="{ animate: timeRemaining > 0 }">{{ timeRemaining }}</p>
  </div>
</template>

<script>
import quizConfig from "@/quizConfig"; // Import der Quiz-Konfiguration

export default {
  name: 'CountdownPage',
  data() {
    return {
      quizConfig: quizConfig,
      timeRemaining: quizConfig.startTimerLength, // Timer Startwert in Sekunden aus config
      isAnimating: false
    };
  },
  //started Countdown-Methode sobald die Komponente geladen wird
  mounted() {
    this.startCountdown();

    this.fingersnip = new Audio(require("@/assets/fingersnaps.wav"));
    this.fingersnip.play().catch(error => {
      console.warn("Fingersnip konnte nicht gestartet werden", error);
    });
  },
  methods: {
    // Countdown-Methode
    startCountdown() {
      const countdownInterval = setInterval(() => {
        if (this.timeRemaining > 0) {
          this.triggerAnimation();
          this.timeRemaining--;
          this.fingersnip.play();
        } else {
          clearInterval(countdownInterval);
          this.fingersnip.pause();
          this.fingersnip.currentTime = 0; // Zurücksetzen des Audiopfades
          this.$emit('countdown-finished');
        }
      }, 1000);
    },
    triggerAnimation() {
      // Animation aktivieren
      const circleElement = this.$el.querySelector('.circle');
      circleElement.classList.add('animate');

      // Klasse nach der Animation wieder entfernen
      setTimeout(() => {
        circleElement.classList.remove('animate');
      }, 500); // Diese Zeit muss mit der Dauer der Animation übereinstimmen
    }
  }
}
</script>

<style scoped>
.countdown-page {
  text-align: center;
  margin-top: 100px;
  color: white;
}

h1 {
  font-size: 2em;
  color: white;
}

/* Styles für den Countdown-Zähler */
.circle {
  margin-top: 0;
  font-size: 8em;
  width: 250px;
  height: 250px;
  line-height: 250px; /* Vertikale Zentrierung des Textes */
  border-radius: 50%;
  display: inline-block;
  background-color: #f0f0f0;
  color: #010440;
  transition: transform 0.3s ease, color 0.3s ease;
}

/* Animation für den Countdown-Zähler */
.circle.animate {
  animation: pulse 0.5s ease-in-out;
}

/* Keyframes für die Puls-Animation */
@keyframes pulse {
  0% {
    transform: scale(1); /* Ausgangsgröße */
  }
  50% {
    transform: scale(1.2); /* Maximale Größe */
  }
  100% {
    transform: scale(1); /* Zurück zur Ausgangsgröße */
  }
}

/* Responsive Styles */
@media (max-width: 768px) {
  /* Für kleinere Bildschirme */
  h1 {
    font-size: 1.5em; /* Kleinere Schriftgröße für h1 */
  }

  .circle {
    font-size: 6em;
    width: 200px;
    height: 200px;
    line-height: 200px;
  }
}

@media (max-width: 480px) {
  /* Für sehr kleine Bildschirme */
  h1 {
    font-size: 1.2em;
  }

  .circle {
    font-size: 5em;
    width: 150px;
    height: 150px;
    line-height: 150px;
  }
}
</style>
