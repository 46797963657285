<template>
  <b></b>
  <!-- Vue rendering, die gewollte Komponente wird dynamisch bei auslösen des Ereignisses geladen -->
  <component :is="currentView" @start-countdown="startCountdown" @countdown-finished="startQuiz"
             @quiz-ended="loadEndPage" :score="score"></component>
</template>

<script>
/* eslint-disable no-unused-vars */
/* Comment obendrüber sehr wichtig, da sonst ein Fehler auftritt. NICHT ENTFERNEN! */

// Import der zu ladenden Komponenten
import LandingPage from "@/components/LandingPage.vue";
import CountdownPage from "@/components/CountdownPage.vue";
import QuizPage from "@/components/QuizPage.vue";
import EndPage from '@/components/EndPage.vue';

export default {
  name: 'App',
  components: {
    LandingPage,
    CountdownPage,
    QuizPage,
    EndPage,
  },
  data() {
    return {
      currentView: 'LandingPage',
      score: 0
    };
  },
  // Methoden, die die Komponenten wechseln
  methods: {
    startCountdown() {
      this.currentView = 'CountdownPage';
    },
    startQuiz() {
      this.currentView = 'QuizPage';
    },
    loadEndPage(score) {
      this.score = score; // Punktestand aus Quizpage wird übergeben
      this.currentView = 'EndPage';
    }
  }
}
</script>
