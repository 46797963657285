<template>
  <div class="quiz-page">

    <div class="header">
      <!-- Timer, Fragezähler und Punktestand -->
      <div class="timer-score-questionTrack-container">
        <div class="timer">
          <h1>{{ minutes }}:{{ seconds }}</h1>
        </div>
        <div class="question-counter">
          <h1>Frage {{ currentQuestionIndex + 1 }}/{{ questions.length }}</h1>
        </div>
        <div class="score">
          <h1>Punkte: {{ score }}</h1>
        </div>
      </div>
    </div>
    <!-- Leaderboard Komponente einfügen -->
    <scoreboard></scoreboard>
    <div class="quiz-content">
      <div class="question-container">
        <div class="question">
          <p>{{ currentQuestion.question }}</p> <!-- Frage wird geladen -->
        </div>
      </div>

      <div class="answers">
        <!-- Buttons für Antwortmöglichkeiten -->
        <button v-for="(answer, index) in currentQuestion.answers" :key="index"
                @click="selectAnswer(answer)"
                @dblclick="confirmSelection"
                :class="{ selected: selectedAnswer === answer }">
          {{ answer }}
        </button>
      </div>
      <!-- Bestätigungsbutton -->
      <button @click="confirmSelection" :disabled="!selectedAnswer">Bestätigen</button>
    </div>
  </div>
</template>
<script>
import quizConfig from "@/quizConfig"; // Import der Quiz-Konfiguration
import questions from '@/assets/questions.json'; // Import der Fragen
import {getSocket} from '@/socket'; // Import der Socket-Verbindung
import Scoreboard from "@/components/ScoreboardAPI.vue";
import confetti from "canvas-confetti";


export default {
  name: 'QuizPage',
  components: {Scoreboard},

  data() {
    return {
      quizConfig: quizConfig,
      time: quizConfig.timerLength, // Zeit ab start des Quiz in Sekunden
      interval: null, // Macht es möglich den Timer zu stoppen und wieder zu starten
      questions: [], // Array für Fragen und Antworten Katalog
      currentQuestionIndex: 0, // Index der aktuellen Frage
      selectedAnswer: null, // Ausgewählte Antwort
      score: 0, // Punktestand des Spielers
      quizEnded: false, // Flag, um zu überprüfen, ob das Quiz beendet ist
      backgroundMusic: null, // Neue Eigenschaft für die Hintergrundmusik
      correctSound: null,
      wrongSound: null
    };
  },

  computed: {

    // Berechnung der Minuten und Sekunden und Umwandlung in String
    minutes() {
      return Math.floor(this.time / 60).toString().padStart(2, '0');
    },

    seconds() {
      return (this.time % 60).toString().padStart(2, '0');
    },

    // Lädt die aktuelle Frage und zugehörige Antworten
    currentQuestion() {
      return this.questions[this.currentQuestionIndex] || {
        question: '',
        answers: [],
        correctAnswer: ''
      };
    },
  },

  methods: {

    //quelle: https://www.kirilv.com/canvas-confetti/
    fireConfetti() {
      var count = 200;
      var defaults = {
        origin: {y: 0.7}
      };

      function fire(particleRatio, opts) {
        confetti({
          ...defaults,
          ...opts,
          particleCount: Math.floor(count * particleRatio)
        });
      }

      fire(0.25, {
        spread: 26,
        startVelocity: 55,
      });
      fire(0.2, {
        spread: 60,
      });
      fire(0.35, {
        spread: 100,
        decay: 0.91,
        scalar: 0.8
      });
      fire(0.1, {
        spread: 120,
        startVelocity: 25,
        decay: 0.92,
        scalar: 1.2
      });
      fire(0.1, {
        spread: 120,
        startVelocity: 45,
      });
    },

    // Methode für Ablauf des Timers
    startTimer() {
      this.interval = setInterval(() => {
        if (this.time > 0) {
          this.time--;
        } else {
          clearInterval(this.interval);
          this.confirmSelection(true); // Bestätigt die Auswahl, wenn der Timer abläuft
        }
      }, 1000);
    },

    // Methode, die den ausgewählten Button als gegebene Antwort speichert
    selectAnswer(answer) {
      this.selectedAnswer = answer;
    },

    // Methode, die die Auswahl bestätigt und die nächste Frage lädt oder das Quiz beendet
    // wird aufgerufen, wenn der Bestätigungsbutton geklickt wird
    confirmSelection(timerRunsOut = false) {
      if (this.quizEnded || (!this.selectedAnswer && !timerRunsOut)) {
        return;
      }

      const isCorrect = this.selectedAnswer === this.currentQuestion.correctAnswer;
      const selectedButton = this.$el.querySelector('.answers button.selected');

      /* Wenn die richtige Antwort gewählt und abgegeben wurde
         Ändert die Farbe des Buttons für 0.5 sek auf grün und erhöht die Punktzahl
         Lädt nächste Frage nach 0.5 sek */
      if (selectedButton) {
        if (isCorrect) {
          selectedButton.classList.add('correct'); // Färbt den Button grün
          this.fireConfetti();
          this.correctSound.play();
          setTimeout(() => {
            selectedButton.classList.remove('correct'); // Entfernt die Farbe nach 0.5 sek
            this.score++;
            const socket = getSocket();
            // Ändert die Punktzahl des Spielers auf Server-Ebene
            socket.emit('updatePoints', {score: this.score});

            this.loadNextQuestion();
          }, 500);
        }

        /* Wenn die falsche Antwort gewählt und abgegeben wurde
           Ändert die Farbe des Buttons für 0.5 sek auf rot
           Lädt nächste Frage nach 0.5 sek */
        else {
          selectedButton.classList.add('wrong'); // Färbt den Button rot
          this.wrongSound.play();
          setTimeout(() => {
            selectedButton.classList.remove('wrong'); // Entfernt die Farbe nach 0.5 sek
            this.loadNextQuestion();
          }, 500);
        }
      } else {
        this.loadNextQuestion();
      }
    },

    // Methode, die die nächste Frage lädt
    loadNextQuestion() {
      if (this.currentQuestionIndex < this.questions.length - 1) {
        this.currentQuestionIndex++;
        const socket = getSocket();
        // Ändert die aktuell bearbeitete Frage auf Server-Ebene
        socket.emit('updateIndex', {currentQuestionIndex: this.currentQuestionIndex});
        this.selectedAnswer = null; // Resetet die Knopfauswahl
        this.resetTimer(); //Resetet den Timer
      } else {
        this.currentQuestionIndex++;
        getSocket().emit('updateIndex', {currentQuestionIndex: this.currentQuestionIndex});
        this.quizEnded = true;
        this.$emit('quiz-ended', this.score); // Wirft ein Event, wenn das Quiz beendet ist
      }
    },


    //initiales Laden der Fragen
    loadQuestions() {
      this.questions = questions;
    },

    // Methode, die den Timer zurücksetzt
    resetTimer() {
      clearInterval(this.interval);
      this.time = this.quizConfig.timerLength; // Setzt die Zeit auf den Startwert zurück
      this.startTimer(); // Startet den Timer neu
    }
  },


  // Initialisierung des Timers, Laden der Fragen und Verbindung zum Socket
  mounted() {
    this.startTimer();
    this.loadQuestions();
    getSocket();

    // Musik abspielen, wenn die Komponente geladen wird
    this.backgroundMusic = new Audio(require('@/assets/backgroundMusik.mp3'));
    this.backgroundMusic.loop = true; // Musik in Schleife
    this.backgroundMusic.play().catch(error => {
      console.warn("Die Musik konnte nicht automatisch gestartet werden:", error);
    });
    this.correctSound = new Audio(require('@/assets/correct.wav'));
    this.wrongSound = new Audio(require('@/assets/wrong.wav'));
  },

  // Beendet den Timer, wenn die Komponente entfernt wird
  beforeUnmount() {
    clearInterval(this.interval);

    // Musik stoppen, wenn die Komponente entfernt wird
    if (this.backgroundMusic) {
      this.backgroundMusic.pause();
      this.backgroundMusic.currentTime = 0; // Startet die Musik von Anfang
    }
  }
};
</script>

<style scoped>
/* Styling für die gesamte Quiz-Seite */
.quiz-page {
  text-align: center;
  margin-top: 0;
  color: black;
  padding: 10px; /* Padding für mobile-first */
}

/* Header: Enthält Timer, Fragezähler und Punktestand */
.header {
  display: flex;
  flex-direction: column; /* Mobiles Layout: Header-Elemente untereinander */
  align-items: center;
  margin-bottom: 20px;
}

/* Container für Timer, Fragezähler und Punktestand */
.timer-score-questionTrack-container {
  display: flex;
  flex-direction: column; /* Mobiles Layout: Spaltenformat */
  gap: 10px; /* Abstand zwischen den Elementen */
  align-items: center;
}

.timer h1, .question-counter h1, .score h1 {
  font-size: 1.2em; /* Kleinere Schriftgröße für Mobilgeräte */
  margin: 0;
  color: white;
}

/* Quiz-Inhalte */
.quiz-content {
  margin-top: 10px; /* Kleinerer Abstand auf Mobilgeräten */
  padding: 10px; /* Extra Padding für Mobilgeräte */
}

.question-container {
  height: calc(2.5em * 4);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden; /* Verhindert Größenänderungen durch zu langen Text */
  text-align: center;
}

.question {
  font-size: 1.2em;
  color: white;
  line-height: 1.2em; /* Setzt die Zeilenhöhe */
  max-height: calc(1.2em * 3); /* Begrenzt die Höhe auf drei Zeilen */
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Begrenzung auf drei Zeilen */
  -webkit-box-orient: vertical;
}

/* Anordnung der Antwort-Buttons */
.answers {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Zweispaltiges Layout für kleine Bildschirme */
  gap: 10px; /* Abstand zwischen den Antwort-Buttons */
  max-width: 100%; /* Maximale Breite der Antwort-Buttons für Mobilgeräte */
  margin: 0 auto;
}

/* Styling für die Antwort-Buttons */
.answers button {
  font-size: 0.9em; /* Kleinere Schriftgröße für Mobilgeräte */
  padding: 8px; /* Weniger Padding für Mobilgeräte */
  background-color: #f0f0f0;
  color: black;
  border: 2px solid #404040;
  border-radius: 10px; /* Abrundung der Ecken */
  cursor: pointer;
  width: 100%;
  box-sizing: border-box; /* Padding und Border werden in die Breite und Höhe des Elements einberechnet */
  min-height: 40px;
}

/* Farbänderung des Buttons bei Auswahl */
.answers button.selected {
  background-color: #606060FF;
  color: white;
}

/* Farbänderung des Buttons bei richtiger Antwort */
.answers button.correct {
  background-color: green;
  color: white;
}

/* Farbänderung des Buttons bei falscher Antwort */
.answers button.wrong {
  background-color: red;
  color: white;
}

/* Styling des Bestätigungs-Buttons */
button {
  margin-top: 10px;
  font-size: 1.2em;
  padding: 8px 16px;
  background-color: #4F8C6F;
  color: white;
  border: none;
  border-radius: 5px;
}

/* Anpassungen für Tablets und Laptops */
@media (min-width: 600px) {
  .timer-score-questionTrack-container {
    flex-direction: row; /* Zeilenlayout bei breiteren Bildschirmen */
    gap: 20px; /* Größerer Abstand zwischen den Elementen */
  }

  .timer h1, .question-counter h1, .score h1 {
    font-size: 1.5em; /* Größere Schriftgröße für breitere Bildschirme */
  }

  .answers {
    grid-template-columns: repeat(2, 1fr); /* Zweispaltiges Layout bleibt bestehen */
    max-width: 70%; /* Antworten-Container wird etwas schmaler */
  }

  button {
    font-size: 1.5em; /* Größere Schriftgröße für den Bestätigungs-Button */
    padding: 10px 20px;
  }
}

/* Anpassungen für Laptops und Desktops */
@media (min-width: 1024px) {
  .quiz-page {
    max-width: 800px; /* Beschränkte Breite für bessere Lesbarkeit */
    margin: 0 auto;
  }

  .question {
    font-size: 1.8em; /* Größere Schriftgröße für Fragen */
  }

  .answers {
    grid-template-columns: repeat(2, 1fr); /* Zweispaltiges Layout bleibt bestehen */
    max-width: 60%; /* Weitere Anpassung der Breite */
  }

  .timer h1, .question-counter h1, .score h1 {
    font-size: 2em; /* Größere Schriftgröße für Header-Elemente */
  }
}
</style>

