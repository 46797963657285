<template>
  <div class="end-page">
    <div class="logo-text-container">
      <img alt="Quiz logo" src="@/assets/HTML_Heroes_Logo.png">
      <span class="logo-text">HTML-HEROES</span>
    </div>
    <h1>Geschafft! Du hast das Quiz beendet.</h1>
    <p>Dein finaler Punktestand: {{ score }}</p>
    <!-- Leaderboard Komponente einfügen -->
    <scoreboard></scoreboard>
  </div>
</template>

<script>
import Scoreboard from "@/components/ScoreboardAPI.vue";
import confetti from "canvas-confetti";

export default {
  name: 'EndPage',
  components: {Scoreboard,},

  props: {
    // Punktestand wird von QuizPage übergeben
    score: {
      type: Number,
      required: true
    }
  },
  mounted() {
    this.doneSound = new Audio(require("@/assets/done.ogg"));
    this.doneSound.play().catch(error => {
      console.warn("DoneSound canceld", error)
    })

    this.fireConfetti();
  },
  methods: {
    // Konfetti Animation (quelle: https://www.kirilv.com/canvas-confetti/)
    fireConfetti() {
      var duration = 15 * 1000;
      var animationEnd = Date.now() + duration;
      var defaults = {startVelocity: 30, spread: 360, ticks: 60, zIndex: 0};

      function randomInRange(min, max) {
        return Math.random() * (max - min) + min;
      }

      var interval = setInterval(function () {
        var timeLeft = animationEnd - Date.now();

        if (timeLeft <= 0) {
          return clearInterval(interval);
        }

        var particleCount = 50 * (timeLeft / duration);
        confetti({...defaults, particleCount, origin: {x: randomInRange(0.1, 0.3), y: Math.random() - 0.2}});
        confetti({...defaults, particleCount, origin: {x: randomInRange(0.7, 0.9), y: Math.random() - 0.2}});
      }, 250);
    },
  }
};
</script>

<style scoped>
.end-page {
  text-align: center;
  margin-top: 50px;
}

.end-page h1 {
  font-size: 2em;
  color: white;
  text-shadow: 4px 4px 8px rgba(0, 0, 0, 0.7);
}

.end-page p {
  font-size: 1.5em;
  color: white;
  text-shadow: 4px 4px 8px rgba(0, 0, 0, 0.7);
}

.leaderboard h2 {
  font-size: 2em;
}

.leaderboard {
  margin: 20px auto;
  border: 2px solid #404040;
  padding: 20px;
  border-radius: 10px;
  background-color: white;
  max-width: 50%;
}

.logo-text-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20vh;
}

/* Styling des Logos */
img {
  width: 100px;
  height: 100px;
}

/* Styling des Textes */
.logo-text {
  font-size: 2.5rem;
  font-weight: bold;
  margin-left: 10px;
  color: white;
  text-shadow: 4px 4px 8px rgba(0, 0, 0, 0.7);
}

/* Media Queries für verschiedene Bildschirmgrößen */
@media (max-width: 600px) {
  img {
    width: 70px; /* Kleinere Größe für mobile Geräte */
    height: 70px;
  }


  .logo-text {
    font-size: 1.2rem; /* Kleinere Schriftgröße für mobile Geräte */
  }
}

@media (min-width: 601px) and (max-width: 1024px) {
  img {
    width: 90px; /* Mittelgroße Größe für Tablets */
    height: 90px;
  }

  .logo-text {
    font-size: 2.2rem; /* Mittelgroße Schriftgröße für Tablets */
  }
}

@media (min-width: 1025px) {
  img {
    width: 100px; /* Standardgröße für Desktop */
    height: 100px;
  }

  .logo-text {
    font-size: 2.5rem; /* Standard Schriftgröße für Desktop */
  }
}

/* Responsive Styles */
@media (max-width: 600px) {
  .end-page h1 {
    font-size: 1.5em; /* Kleinere Schriftgröße für kleine Bildschirme */
  }

  .end-page p {
    font-size: 1.2em; /* Kleinere Schriftgröße für kleine Bildschirme */
  }
}

@media (min-width: 600px) {
  .end-page h1 {
    font-size: 2.5em; /* Größere Schriftgröße für größere Bildschirme */
  }

  .end-page p {
    font-size: 1.8em; /* Größere Schriftgröße für größere Bildschirme */
  }
}

@media (min-width: 1024px) {
  .leaderboard {
    max-width: 70%; /* Größere Breite für das Leaderboard auf großen Bildschirmen */
  }
}
</style>