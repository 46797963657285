<template>
  <div class="landing-page">
    <div class="logo-text-container">
      <img alt="Quiz logo" src="@/assets/HTML_Heroes_Logo.png"> <!-- Logo wird geladen -->
      <span class="logo-text">HTML-HEROES</span>

    </div>
    <!-- NameBox Komponente wird geladen und das Interne Event beim Auslösen geparsed-->
    <NameBox @start-countdown="$emit('start-countdown')"/>
  </div>
</template>

<script>
import NameBox from "@/components/NameBox.vue";

export default {
  name: 'LandingPage',
  components: {NameBox},
  data() {
    return {
      showNameBox: true, // NameBox standardmäßig sichtbar
    };
  },
  mounted() {
    // overflow hidden setzen, wenn die Komponente gemountet wird
    document.body.style.overflow = 'hidden';
  },
  beforeUnmount() {
    // overflow wieder auf auto setzen, wenn die Komponente zerstört wird
    document.body.style.overflow = 'auto';
  },
  methods: {
    goToEndPage() {
      this.$emit('quiz-ended', 0);
    },
  }
}
</script>

<style scoped>
.landing-page {
  text-align: center;
  color: black;
  padding: 20px; /* Padding für mobile-first */

  h1 {
    margin-top: 0;
    font-size: 2em;
  }

  p {
    font-size: 1em;
  }

}

/* Flexbox container für Logo und Text */
.logo-text-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20vh;
}

/* Styling des Logos */
img {
  width: 100px;
  height: 100px;
}

/* Styling des Textes */
.logo-text {
  font-size: 2.5rem;
  font-weight: bold;
  margin-left: 10px;
  color: white;
  text-shadow: 4px 4px 8px rgba(0, 0, 0, 0.7);
}

/* Media Queries für verschiedene Bildschirmgrößen */
@media (max-width: 600px) {
  img {
    width: 70px; /* Kleinere Größe für mobile Geräte */
    height: 70px;
  }


  .logo-text {
    font-size: 1.2rem; /* Kleinere Schriftgröße für mobile Geräte */
  }
}

@media (min-width: 601px) and (max-width: 1024px) {
  img {
    width: 90px; /* Mittelgroße Größe für Tablets */
    height: 90px;
  }

  .logo-text {
    font-size: 2.2rem; /* Mittelgroße Schriftgröße für Tablets */
  }
}

@media (min-width: 1025px) {
  img {
    width: 100px; /* Standardgröße für Desktop */
    height: 100px;
  }

  .logo-text {
    font-size: 2.5rem; /* Standard Schriftgröße für Desktop */
  }
}

/* Media Queries für verschiedene Bildschirmgrößen */
@media (max-width: 600px) {
  .landing-page {
    h1 {
      font-size: 1.5em; /* Kleinere Schriftgröße für mobile Geräte */
    }

    button {
      font-size: 1.2em; /* Kleinere Schriftgröße für mobile Geräte */
      padding: 8px 16px; /* Kleinere Padding für mobile Geräte */
    }
  }
}

@media (min-width: 601px) and (max-width: 1024px) {
  .landing-page {
    h1 {
      font-size: 1.75em; /* Mittelgroße Schriftgröße für Tablets */
    }

    button {
      font-size: 1.4em; /* Mittelgroße Schriftgröße für Tablets */
      padding: 10px 18px; /* Mittelgroße Padding für Tablets */
    }
  }
}

@media (min-width: 1025px) {
  .landing-page {
    h1 {
      font-size: 2em; /* Standard Schriftgröße für Desktop */
    }

    button {
      font-size: 1.5em; /* Standard Schriftgröße für Desktop */
      padding: 10px 20px; /* Standard Padding für Desktop */
    }
  }
}
</style>
