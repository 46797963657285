// Socket-Verbindung zum Webserver
import {io} from 'socket.io-client';

let socket;

export function getSocket() {
    if (!socket) {
        socket = io('html-heroes-production.up.railway.app');
    }
    return socket;
}