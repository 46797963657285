<!-- Echtzeit Scoreboard Komponente für QuizPage und EndPage -->
<template>
  <div class="leaderboard">
    <div class="leaderboard-header">
      <span class="header-rank">Rank</span>
      <span class="header-name">Name</span>
      <span class="header-score">Score</span>
      <span class="header-status">Frage</span>
    </div>

    <div class="leaderboard-body">
      <div v-for="(player, index) in sortedLeaderboard" :key="player.socketId" class="leaderboard-row">
        <span class="rank">{{ index + 1 }}</span>
        <div class="player-info">
          <span class="player-name">{{ player.username }}</span>
        </div>
        <span class="score">{{ player.punktzahl }}</span>
        <span class="status">{{ player.index }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import {getSocket} from "@/socket";

export default {
  computed: {
    sortedLeaderboard() {
      return Object.values(this.leaderboard).sort((a, b) => b.punktzahl - a.punktzahl);
    }
  },
  data() {
    return {
      leaderboard: {},
    };
  },
  mounted() {
    const socket = getSocket();
    socket.on('updateLeaderboard', (players) => {
      this.leaderboard = players;
    });
    socket.emit('requestLeaderboard');
  },
  methods: {}
};
</script>

<style scoped>

.leaderboard {
  max-width: 600px;
  margin: 20px auto;
  background-color: white;
  border-radius: 8px;
  padding: 10px;
  font-family: Arial, sans-serif;
}

.leaderboard-body {
  max-height: 100px;
  overflow-y: auto; /* Scrollen aktivieren */
  color: white;
}

.leaderboard-header,
.leaderboard-row {
  display: flex;
  align-items: center;
  padding: 5px 0;
}

.leaderboard-header {
  font-weight: bold;
  color: #010440;
  border-bottom: 2px solid #010440;;
}

.header-rank,
.header-name,
.header-score,
.header-status {
  flex: 1;
  font-size: 0.8em;
  text-align: center;
}

.leaderboard-row {
  background-color: #010440;
  border-radius: 4px;
  margin: 8px 0;
}

.rank,
.player-info,
.score,
.status {
  flex: 1;
  text-align: center;
  font-size: 0.6em;
}

.player-info {
  display: flex;
  align-items: center;
  justify-content: center;
}

.player-name {
  font-weight: bold;
}

.score {
  font-weight: bold;
}
</style>