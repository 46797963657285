<template>
  <div class="input-container">
    <div class="input-box">
      <!-- Username Eingabefeld. Maximale Länge aus config -->
      <input type="text" v-model="username" :maxlength="quizConfig.maxUsernameLength" placeholder="Nutzername"
             @keyup.enter="startCountdown">
      <button @click="startCountdown">Start</button>
    </div>
  </div>
</template>

<script>
import quizConfig from "@/quizConfig"; // Import der Quiz-Konfiguration
import {getSocket} from '@/socket'; // Import der Socket-Verbindung

export default {
  name: 'NameBox',
  data() {
    return {
      username: '',
      joinedMessage: "",
      quizConfig: quizConfig,
    };
  },
  methods: {
    // Methode zum Starten des Countdowns / triggert die register Methode
    startCountdown() {
      if (!this.username.trim()) {
        alert('Bitte gib einen Benutzernamen ein.');
        return;
      }
      console.log(`Name entered: ${this.username}`);
      this.register(); // Ruft die register Methode auf
      this.$emit('start-countdown');
    },

    // Methode zum Registrieren des Benutzernamens
    register() {
      const socket = getSocket();

      // Nach erfolgreicher Verbindung
      socket.on('connect', () => {
        console.log('Verbunden mit dem Socket.io-Server');
        // Sendet den Benutzernamen an den Server
        socket.emit('register', this.username);
      });

      // Fehlerbehandlung bei Verbindungsfehlern
      socket.on('connect_error', (error) => {
        console.error('Verbindungsfehler:', error);
        alert('Verbindung zum Server fehlgeschlagen. Bitte versuche es später erneut.');
      });
    }
  },
};
</script>

<style scoped>
.input-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35vh;
}

.input-box {
  border: 2px solid white;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column; /* Button unter das Textfeld verschieben */
  align-items: center;
  width: 300px;
  background: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

input {
  font-size: 1.5em;
  padding: 10px;
  margin-bottom: 20px;
  border: 2px solid gray;
  border-radius: 10px;
  background: white;
  outline: none;
  text-align: center;
  width: 100%;
}

button {
  font-size: 1.5em;
  padding: 10px 20px;
  background-color: gray;
  color: white;
  cursor: pointer;
  border-radius: 10px;
  width: 100%;
  border-color: #010440;
}

button:hover {
  background-color: #003bb5;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .input-box {
    width: 80%; /* Breite der Box für kleinere Bildschirme */
    padding: 15px; /* Etwas weniger Padding für kleinere Bildschirme */
  }

  input,
  button {
    font-size: 1.2em;
  }

  .input-container {
    height: 30vh;
  }
}

@media (max-width: 480px) {
  .input-box {
    width: 80%; /* Breite der Box für sehr kleine Bildschirme */
    padding: 15px; /* Noch weniger Padding */
  }

  .input-container {
    height: 20vh;
  }

  input,
  button {
    font-size: 1em; /* Noch kleinere Schriftgröße */
  }
}

@media (min-height: 1100px) {
  .input-box {
    width: 80%; /* Breite der Box für kleinere Bildschirme */
    padding: 15px; /* Etwas weniger Padding für kleinere Bildschirme */
  }

  input,
  button {
    font-size: 1.2em; /* Kleinere Schriftgröße für Input und Button */
  }

  .input-container {
    height: 17vh;
  }
}
</style>
